<template>
    <div>
        <!-- footer wrapper -->
        <div class="footer-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-left">
                        <h4 class="mb-4">Streaming de eventos, combinando lo último en tecnología con los mejores eventos en diferentes sectores y rubros. Nuestra misión es conectar al mayor número de personas con el contenido de su interés.</h4>
                    </div>
                    <div class="col-sm-6 text-left">
                        <img src="/images/logo-dark.png" alt="icon" height="50" class="icon-img"> 
                    </div>
                    <div class="col-sm-5 offset-sm-1 text-right">
                        <form action="#" class="mt-0">
                            <input type="text" placeholder="Email">
                            <button>Buscar</button>
                        </form>                    
                    </div>

                    <div class="col-sm-12 lower-footer"></div>
                </div>
                
            </div>
            
            <div class="container">
            <div class="row">
                <div class="col-6 text-left">
                    <p class="float-left copyright-text">© 2020 Copyright DirectXLive. 
                        <br>Todos los derechos reservados.</p>
                </div>
                <div class="col-6 text-right">
                    <p class="float-right copyright-text">Av Coyoacán 1622 Ed 4 Piso 2 - A, Del Valle 03100,<br> Benito Juárez, CDMX (55) 4164 - 6991   </p>
                </div>
            </div>
            </div>
            
        </div>
        <!-- footer wrapper -->
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>