/* eslint-disable */
<template>
    <div>        
        

        <!-- slider wrapper -->
        <div class="category-wrapper slide-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-left mb-4 mt-1">
                        <h2>Categorías</h2>
                    </div>
                     
                </div>
                <div class="row">
                   <div class="owl-items col-sm-6 col-md-3" v-for="category in categories" :key="category.id">
                        <a 
                            href="#"
                            class="category-wrap" :style="'background-image: url(images/gb'+category.id+'.png);'">
                            <span>{{category.name}}</span>
                        </a>
                    </div>
                </div>
            </div>            
        </div>
        <!-- slider wrapper -->
        <!-- slider wrapper -->
        <div class="slide-wrapper">
            <div class="container">
                <div class="row owl-carousel">
                    <div class="col-sm-6 text-left mb-4 mt-1">
                        <h2>En Vivo</h2>
                    </div>
                     
                </div>
                <div v-if="this.events.length>0">
                    <div class="row">
                        <div :class="'owl-items col-sm-12 col-md-'+event_length" v-for="event in events" :key="event.id">
                            <a class="slide-one" :href="'/live/'+event.slug">
                                <div class="slide-image"><img :src="event.cover_url" alt="image"></div>
                                <div class="slide-content">
                                    <h2>
                                        {{event.title}} 
                                        <!-- <img src="images/plus.png" class="add-wishlist" alt="icon"> -->
                                    </h2>
                                    <p>{{event.description}}</p>
                                    <span class="tag">{{event.date_start_format}}</span>
                                    <span class="tag"><b>Live HD</b></span>
                                    <!-- <span class="tag"><b>16+</b></span> -->
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="container" v-else>
                    <div class="search-wrapper text-center">
                        <!-- <h2 class="mb-3">Watchlist</h2> -->
                        <p class="mb-0">Por el momento no hay eventos en vivo...</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- slider wrapper -->
                            
                            <!-- <div v-for="event in events" :key="event.id">
                                <div class="owl-items">
                                    <a class="slide-one" :href="'/live/'+event.slug">
                                        <div class="slide-image"><img src="https://via.placeholder.com/400x400.png" alt="image"></div>
                                        <div class="slide-content">
                                            <h2>{{event.title}}</h2>
                                            <p>{{event.description}}</p>
                                            <span class="tag">{{event.date_start_format}}</span>
                                            <span class="tag">2020</span>
                                            <span class="tag"><b>HD</b></span>
                                            <span class="tag"><b>{{event.venue}}</b></span>
                                        </div>
                                    </a>
                                </div>
                            </div> -->

                            
                               

    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data: function(){
            return {
                app_url: process.env.VUE_APP_URL,
                events: [],
                event_length: 0,
                categories: []
            }
        },
        methods: {
            get_events(){
                // axios.get('https://ima.dueacademy.com/dxl/events')
                axios.get(this.app_url+'/dxl/events-live')
                        .then( (response) => {
                            if(response.data.status){
                                // console.log("++++++++"+this.app_url);
                                // console.log(response.data.message);
                                this.events = response.data.message;
                                if(this.events.length>0) this.event_length = 4;
                                if(this.events.length>1) this.event_length = 4;
                                if(this.events.length>2) this.event_length = 4;
                                if(this.events.length>3) this.event_length = 3;
                                // console.log(this.event);
                            } else {
                                // console.log("Event doesn't exitst.");
                                // window.location.href = "/";
                            }
                            
                        })
                        .catch( (error) => {
                            console.log(error);
                        });
            },
            get_categories(){
                axios.get(this.app_url+'/dxl/event-categories')
                        .then( (response) => {
                            if(response.data.status){
                                // console.log("**YES!");
                                // console.log(response.data.message);
                                this.categories = response.data.message;
                            }                            
                        })
                        .catch( (error) => {
                            console.log(error);
                        });
            }
        },
        mounted(){
            this.get_categories();
            this.get_events();            
            // console.log(this.events);
            // this.events = Object.entries(this.events);
        }
    }
</script>

<style>
.center {
  margin: auto;
  width: 50%;
  /* border: 3px solid #b53940; */
  padding: 10px;
}
</style>