<template>
  <div>
    
   
    <div class="bg-img-hero gradient-y-overlay-landing">
        <div class="container d-flex justify-content-center h-845 space-top-3 space-bottom-2 space-md-0">
        <div class="text-center mt-md-auto space-bottom-md-3 px-xl-8">
        <h1 class="display-8 mb-2 text-white">Eventos Live</h1>
        <div class="font-size-26-r text-white mb-5 font-secondary">
            Videos, Eventos, Congresos & mucho más...
            <!-- Best movies, shows & lives for $9/monthly. -->
        </div>
        <a href="/live" class="btn btn-primary btn-pill text-lh-1dot25 py-3 font-secondary font-weight-medium font-size-18-r px-6">Ver Eventos</a>
        </div>
        </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

export default {
  name: "Home",
  components: {
    
  },
  data: function(){
      return {
          app_url: process.env.VUE_APP_URL,
          events: []
      }
  },
  methods: {
      get_events(){
          axios.get(this.app_url+'/dxl/events')
                .then( (response) => {
                    console.log(response.data);
                    this.events = response.data.message;
                    console.log(this.events);
                })
                .catch( (error) => {
                    console.log(error);
                });
      }
  },
  mounted(){
    //   this.get_events();
    // console.log(this.events);
    this.events = Object.entries(this.events);
  }
};
</script>

<style>
.bg-img-hero{background-size:cover;background-repeat:no-repeat;background-position:top center}
.gradient-y-overlay-landing{
    /* background-image:linear-gradient(0deg,rgba(0,0,0,0.45) 0%,rgba(0,0,0,0.7) 100%) */
    background-image:
    linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(181, 57, 64, 0.73)),
    url('/images/background_main_01.jpg');
}
.justify-content-center{-ms-flex-pack:center!important;justify-content:center!important}
@media(min-width:768px){.h-845{height:37.5rem}}@media(min-width:992px){.h-845{height:52.812rem}}
.space-top-3{padding-top:8rem!important}
.space-bottom-2{padding-bottom:4rem!important}
@media(min-width:768px){.space-md-0,.space-top-md-0{padding-top:0!important}.space-md-0,.space-bottom-md-0{padding-bottom:0!important}}
.mt-md-auto,.my-md-auto{margin-top:auto!important}
.space-bottom-md-3{padding-bottom:8rem!important}
.px-xl-8{padding-right:3.5rem!important}.pb-xl-8,.py-xl-8{padding-bottom:3.5rem!important}.pl-xl-8,.px-xl-8{padding-left:3.5rem!important}.p-xl-9{padding:4rem!important}
.display-8{font-size:3.125rem;font-weight:700;line-height:1.2}@media(max-width:1200px){.display-8{font-size:calc(1.4375rem + 2.25vw)}}
@media(min-width:768px){.font-size-23-r{font-size:1.437rem}}.font-size-24{font-size:1.5rem}.font-size-26{font-size:1.625rem}.font-size-26-r{font-size:.875rem}@media(min-width:768px){.font-size-26-r{font-size:1.625rem}}
.font-secondary{font-family:montserrat,sans-serif}
.text-lh-1dot25{line-height:1.25}
.py-3{padding-top:1rem!important}
.py-3{padding-bottom:1rem!important}
.font-weight-medium{font-weight:500!important}
@media(min-width:768px){.font-size-16-r{font-size:1rem}}.font-size-17{font-size:1.063rem}.font-size-18{font-size:1.125rem}.font-size-18-r{font-size:.875rem}@media(min-width:768px){.font-size-18-r{font-size:1.125rem}}
.px-6{padding-right:2.5rem!important}
.px-6{padding-left:2.5rem!important}
</style>
