<template>
    <div>
        <!-- <div class="preloader"></div>
        <div class="backdrop"></div>
        <div class="switchcolor">
        <img src="/images/settings.png" alt="icon">
        </div>
        <div class="switchcolor-wrap">
        <a class="link sheet-close"><i class="ti-close"></i></a>
            <h2>Settings</h2>
            <h4>Choose Color Theme</h4>
            <ul>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="red" checked=""><i class="ti-check"></i>
                <span class="circle-color bg-red" style="background-color: #ff3b30;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="green"><i class="ti-check"></i>
                <span class="circle-color bg-green" style="background-color: #4cd964;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="blue" checked=""><i class="ti-check"></i>
                <span class="circle-color bg-blue" style="background-color: #132977;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                    <input type="radio" name="color-radio" value="pink"><i class="ti-check"></i>
                    <span class="circle-color bg-pink" style="background-color: #ff2d55;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="yellow"><i class="ti-check"></i>
                <span class="circle-color bg-yellow" style="background-color: #ffcc00;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="orange"><i class="ti-check"></i>
                <span class="circle-color bg-orange" style="background-color: #ff9500;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="gray"><i class="ti-check"></i>
                <span class="circle-color bg-gray" style="background-color: #8e8e93;"></span>
                </label>
            </li>

            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="brown"><i class="ti-check"></i>
                <span class="circle-color bg-brown" style="background-color: #D2691E;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="darkgreen"><i class="ti-check"></i>
                <span class="circle-color bg-darkgreen" style="background-color: #228B22;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="deeppink"><i class="ti-check"></i>
                <span class="circle-color bg-deeppink" style="background-color: #FFC0CB;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="cadetblue"><i class="ti-check"></i>
                <span class="circle-color bg-cadetblue" style="background-color: #5f9ea0;"></span>
                </label>
            </li>
            <li>
                <label class="item-radio item-content">
                <input type="radio" name="color-radio" value="darkorchid"><i class="ti-check"></i>
                <span class="circle-color bg-darkorchid" style="background-color: #9932cc;"></span>
                </label>
            </li>
            </ul>
            <div class="toggle-div mt-4">
            <h4 class="d-inline">Dark Mode</h4>
            <div class="d-inline float-right">
                <label class="toggle toggle-init"><input type="checkbox"><span class="toggle-icon"></span></label>
            </div>
            </div>
        </div> -->

        <div class="main-wrapper" style="z-index: 5000000;">
            <!-- header wrapper -->
            <div class="header-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 navbar p-0">
                            <a href="/" class="logo"><img src="/images/logo.png" alt="logo" class="light"><img src="/images/logo-white.png" alt="logo" class="dark"></a>
                            <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul class="navbar-nav nav-menu float-none text-center">
                                    <li class="nav-item"><a href="/" class="nav-link"> Home </a></li>
                                    <li class="nav-item"><a href="/live" class="nav-link"> 
                                        <span class="ti-rss-alt"></span> Live
                                        </a>
                                    </li>
                                    <li class="nav-item"><a href="#" class="nav-link"> Videos </a></li>
                                    <li class="nav-item"><a href="#" class="nav-link"> Tienda </a></li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="col-lg-4">
                            
                            <div class="user-avater">
                                <img src="https://via.placeholder.com/50x50.png" alt="user">
                                <div class="user-menu">
                                    <ul>
                                        <li><a href="profile.html"><i class="ti-user"></i>My Profile</a></li>
                                        <li><a href="favorites.html"><i class="ti-heart"></i>My Favorites</a></li>
                                        <li><a href="term.html"><i class="ti-world"></i>Privacy Policy</a></li>
                                        <li><a href="home.html"><i class="ti-power-off"></i>Log Out</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="search-div">
                                <input type="text" placeholder="Search">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- header wrapper -->
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>